/* eslint-disable no-underscore-dangle */
// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { useMedia } from 'react-use';
import { css } from 'styled-components';
import Layout from '../../components/Layout';
import SheetLayout from '../../components/SheetLayout';
import BlockContent from '../../components/BlockContent';
import Button from '../../components/Button';
import {
  MoreSection,
  blockStyles,
  ControlSection,
  CTAWrapper,
  selfBreakpoint,
  SectionTitle,
  StyledSection,
  SplitPanel,
  ContentPanel,
  CTAPanel,
  VenueList,
  Venue,
} from './TrainingTemplate.styles';
import { biggerStyle } from '../../components/Button/button.styles';
import IconArrow from '../../images/IconArrow';
import {
  lessThanCondition,
  greaterThanCondition,
} from '../../utils/mediaQuery';
import colors from '../../styles/colors';
import SEO from '../../components/SEO';
import Header from './Header/Header';
import InfoLine from './InfoLine';
import IconPointer from '../../images/IconPointer';
import IconWatch from '../../images/IconWatch';
import IconPrice from '../../images/IconPrice';
import IconRegister from '../../images/IconRegister';
import BuyTrainingButton from '../../components/BuyTrainingButton';

const addressReducer = (address) => {
  const unit =
    address.unitType && address.unitNumber
      ? `, ${address.unitType} ${address.unitNumber}`
      : undefined;

  return (
    <>
      {address.streetNumber}, {address.streetName}
      {unit} <br />
      {address.city} ({address.province}) {address.postalCode}
    </>
  );
};

const venuesReducer = (venues) => {
  if (venues.length < 1) return undefined;

  return venues.map((venue) => {
    if (venue._type === 'location') {
      const address = addressReducer(venue.address);

      return (
        <Link to={`/nos-cliniques/${venue.slug.current}`}>
          {venue.name} <br />
          {address}
        </Link>
      );
    }

    if (venue._type === 'address') {
      const address = addressReducer(venue);

      return <>{address}</>;
    }

    if (venue._type === 'onlineVenue') {
      return (
        <>
          <b>En Ligne</b>
          {venue.title && (
            <>
              <br /> {venue.title}
            </>
          )}
        </>
      );
    }

    if (venue._type === 'otherVenue') {
      if (!venue.title) return undefined;

      let wrapperProps = {};

      if (venue.url) {
        wrapperProps = {
          ...wrapperProps,
          as: 'a',
          href: venue.url,
          target: '_blank',
          rel: 'noopener noreferrer',
        };
      }

      return <span {...wrapperProps}>{venue.title}</span>;
    }

    return undefined;
  });
};

const scheduleReducer = (startAt, endAt) => {
  if (startAt === null || endAt === null) return undefined;

  const startDate = new Date(startAt);
  const dateLine = startDate.toLocaleString('fr-CA', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'America/Toronto',
  });
  const fromLine = startDate.toLocaleString('fr-CA', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Toronto',
  });
  let toLine;

  if (endAt) {
    const endDate = new Date(endAt);

    toLine = endDate.toLocaleString('fr-CA', {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'America/Toronto',
    });
  }

  return (
    <>
      {dateLine} <br />
      {fromLine}
      {toLine && <> à {toLine}</>}
    </>
  );
};

const priceReducer = (price) => {
  if (price.pricingType === 'noPrice' || price.pricingType === null)
    return undefined;

  if (price.pricingType === 'perPerson') {
    return (
      <>
        {price.amount}$ <br />
        par personne
      </>
    );
  }

  if (price.pricingType === 'perGroup') {
    return (
      <>
        {price.amount}$ <br />
        par groupe
      </>
    );
  }

  if (price.pricingType === 'uponRequest') {
    return <>Prix sur demande</>;
  }

  if (price.pricingType === 'free') {
    return <>Gratuit</>;
  }

  return undefined;
};

const TrainingTemplate = ({ data, pageContext }) => {
  const isMobile = useMedia(lessThanCondition(selfBreakpoint));
  const compactActionButton = useMedia(lessThanCondition(selfBreakpoint));

  const {
    sanityTraining: training,
    allSanityTraining: { edges: trainings },
  } = data;

  const index = trainings.findIndex(
    ({ node: { id } }) => id === pageContext.id
  );

  const nextIndex = index + 1 === trainings.length ? 0 : index + 1;
  const prevIndex = index - 1 < 0 ? trainings.length - 1 : index - 1;

  const nextURL = `/formations/${trainings[nextIndex].node.slug.current}`;
  const prevURL = `/formations/${trainings[prevIndex].node.slug.current}`;

  const addressBlock = venuesReducer(training.venues);

  const scheduleBlock = scheduleReducer(training.startAt, training.endAt);

  const priceBlock = priceReducer(training.price);

  let price;

  if (training.price.pricingType === 'free') {
    price = '0';
  }

  if (training.price.amount) {
    price = String(training.price.amount);
  }

  const CTASection = (
    <CTAWrapper>
      {training.registry && training.registry.registryType === 'online' && (
        <BuyTrainingButton
          outlined
          hasIconOnly={compactActionButton}
          renderIcon={<IconRegister />}
          id={training.id}
          price={price}
          url={`/formations/${training.slug.current}`}
          title={training.title}
        >
          S&apos;inscrire
        </BuyTrainingButton>
      )}

      {training.registry && training.registry.registryType === 'email' && (
        <Button
          outlined
          hasIconOnly={compactActionButton}
          renderIcon={<IconRegister />}
          href={
            training.registry.emailAddress
              ? `mailto:${training.registry.emailAddress}`
              : 'mailto:formations@maxilloquebec.com'
          }
        >
          S&apos;inscrire
        </Button>
      )}

      {training.registry &&
        training.registry.registryType === 'url' &&
        training.registry.url && (
          <Button
            outlined
            hasIconOnly={compactActionButton}
            renderIcon={<IconRegister />}
            href={training.registry.url}
            target='_blank'
            rel='noopener noreferrer'
          >
            S&apos;inscrire
          </Button>
        )}
    </CTAWrapper>
  );

  return (
    <Layout backgroundColor={colors.veryLightPink}>
      <SEO
        title={(training.seo || {}).seo_title || undefined}
        description={(training.seo || {}).meta_description || undefined}
      />

      <article>
        <SheetLayout
          renderHeader={<p>Formation</p>}
          title={training.title}
          actionSection={CTASection}
          dark
        >
          <Header
            title={training.title}
            trainers={training.trainers}
            fluid={training.featuredImage.asset.fluid}
          />

          <div css={blockStyles}>
            <BlockContent
              blocks={training._rawBody}
              imageOptions={{
                maxWidth: 960,
                sizes: `${greaterThanCondition(768)} ${
                  (832 / 992) * 100
                }vw, ${greaterThanCondition(992)} ${
                  (960 / 1200) * 100
                }vw, ${greaterThanCondition(1200)} 960px, ${
                  (721 / 768) * 100
                }vw`,
              }}
            />
          </div>

          {(priceBlock || addressBlock || scheduleBlock) && (
            <StyledSection>
              <SectionTitle>Détails de la formation</SectionTitle>

              <SplitPanel>
                <ContentPanel>
                  {scheduleBlock && (
                    <InfoLine
                      renderIcon={
                        <IconWatch
                          css={css`
                            width: 38px;
                          `}
                        />
                      }
                    >
                      {scheduleBlock}
                    </InfoLine>
                  )}

                  {addressBlock && (
                    <InfoLine
                      renderIcon={
                        <IconPointer
                          css={css`
                            width: 29px;
                          `}
                        />
                      }
                    >
                      <VenueList>
                        {addressBlock.map((block) => (
                          <Venue>{block}</Venue>
                        ))}
                      </VenueList>
                    </InfoLine>
                  )}

                  {priceBlock && (
                    <InfoLine
                      renderIcon={
                        <IconPrice
                          css={css`
                            width: 22px;
                          `}
                        />
                      }
                    >
                      {priceBlock}
                    </InfoLine>
                  )}
                </ContentPanel>

                {training.registry && (
                  <CTAPanel>
                    {training.registry.registryType === 'online' && (
                      <BuyTrainingButton
                        id={training.id}
                        price={price}
                        url={`/formations/${training.slug.current}`}
                        title={training.title}
                        white
                      >
                        {price === '0' ? `S'inscrire` : `Ajouter au panier`}
                      </BuyTrainingButton>
                    )}

                    {training.registry.registryType === 'email' && (
                      <Button
                        href={
                          training.registry.emailAddress
                            ? `mailto:${training.registry.emailAddress}`
                            : 'mailto:formations@maxilloquebec.com'
                        }
                        white
                      >
                        S&apos;inscrire
                      </Button>
                    )}

                    {training.registry.registryType === 'url' &&
                      training.registry.url && (
                        <Button
                          href={training.registry.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          white
                        >
                          S&apos;inscrire
                        </Button>
                      )}
                  </CTAPanel>
                )}
              </SplitPanel>
            </StyledSection>
          )}

          {training.moreDetails.length > 0 && (
            <StyledSection>
              <SectionTitle>Informations supplémentaires</SectionTitle>

              {training.moreDetails.map((detail) => {
                const btnProps = {
                  htef: 'mailto:info@maxilloquebec.com',
                  children: 'Prendre contact',
                };

                let content =
                  'Contactez-nous directement pour obtenir davantage d’informations sur cette formation.';

                if (detail._type === 'moreDetailByUrl') {
                  btnProps.href = detail.address;
                  btnProps.children = 'Voir le site';
                  btnProps.target = '_blank';
                  btnProps.rel = 'noopener noreferrer';
                  content =
                    'Pour obtenir davantage d’informations sur cette formation, consultez le site de l’institution.';
                }

                if (detail._type === 'moreDetailByEmail' && detail.address) {
                  btnProps.href = `mailto:${detail.address}`;
                }

                return (
                  <SplitPanel>
                    <ContentPanel>
                      <p
                        css={css`
                          margin-top: 0;
                        `}
                      >
                        {content}
                      </p>
                    </ContentPanel>

                    <CTAPanel>
                      <Button white outlined {...btnProps} />
                    </CTAPanel>
                  </SplitPanel>
                );
              })}
            </StyledSection>
          )}

          <StyledSection>
            <SectionTitle>Inscription et politique d’annulation</SectionTitle>

            <p>
              Dans le but de concevoir une formation personnalisée et précise,
              le nombre d’inscriptions peut être limité à un certain nombre de
              participants. La confirmation d’inscription vous sera acheminée
              par courriel. L’équipe de MaxilloQuébec se réserve le droit
              d’annuler la formation dans les 14 jours précédents la formation:
              vous serez alors remboursé. Si vous devez annuler votre
              inscription, vous serez remboursé si l’annulation est faite dans
              les 14 jours précédents la formation. Aucun remboursement ne sera
              permis si l’annulation se fait après cette date.
            </p>
          </StyledSection>
        </SheetLayout>

        <MoreSection>
          <ControlSection>
            <Button
              outlined
              css={biggerStyle}
              renderIcon={<IconArrow style={{ transform: 'rotate(180deg)' }} />}
              iconFirst
              hasIconOnly={isMobile}
              to={prevURL}
            >
              Formation précédente
            </Button>

            <Button outlined primary to='/services' css={biggerStyle}>
              Toutes les formations
            </Button>

            <Button
              outlined
              css={biggerStyle}
              renderIcon={<IconArrow />}
              hasIconOnly={isMobile}
              to={nextURL}
            >
              Formation suivante
            </Button>
          </ControlSection>
        </MoreSection>
      </article>
    </Layout>
  );
};

TrainingTemplate.propTypes = {
  data: PropTypes.shape({
    sanityTraining: PropTypes.shape({}).isRequired,
    allSanityTraining: PropTypes.shape({
      edges: PropTypes.arrayOf().isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default TrainingTemplate;

export const query = graphql`
  query($id: String!) {
    allSanityTraining {
      edges {
        node {
          slug {
            current
          }
          id
        }
      }
    }
    sanityTraining(id: { eq: $id }) {
      _rawBody(resolveReferences: { maxDepth: 10 })
      id
      venues {
        ... on SanityAddress {
          _type
          postalCode
          province
          streetName
          streetNumber
          unitNumber
          unitType
          city
        }
        ... on SanityLocation {
          _type
          slug {
            current
          }
          name
          address {
            postalCode
            province
            streetName
            streetNumber
            unitNumber
            unitType
            city
          }
        }
        ... on SanityOnlineVenue {
          _type
          title
        }
        ... on SanityOtherVenue {
          _type
          title
          url
        }
      }
      slug {
        current
      }
      seo {
        meta_description
        seo_title
      }
      title
      startAt
      endAt
      price {
        pricingType
        amount
      }
      registry {
        emailAddress
        registryType
        url
      }
      trainers {
        ... on SanityGuestPresenter {
          _type
          firstname
          lastname
          title
        }
        ... on SanityTeamMember {
          _type
          firstname
          lastname
          title
        }
      }
      moreDetails {
        ... on SanityMoreDetailByEmail {
          _type
          address
        }
        ... on SanityMoreDetailByUrl {
          _type
          address
        }
      }
      featuredImage {
        asset {
          fluid(maxWidth: 802, maxHeight: 802) {
            srcSetWebp
            srcSet
            src
            aspectRatio
            base64
            sizes
          }
        }
      }
    }
  }
`;
