// vendors
import React from 'react';
import PropTypes from 'prop-types';
import buildSpecialistName from '../../../utils/buildSpecialistName';
import {
  Container,
  Content,
  Image,
  Title,
  TrainerItem,
  TrainerList,
  TrainersBlock,
  StyledImage,
} from './Header.styles';

const Header = ({ title, trainers, fluid }) => {
  return (
    <Container>
      <Image>
        <StyledImage fluid={fluid} alt='' role='presentation' />
      </Image>

      <Content>
        <Title>{title}</Title>

        {trainers.length > 0 && (
          <TrainersBlock>
            <p>Formation donnée par</p>

            <TrainerList>
              {trainers.map((trainer) => {
                return (
                  <TrainerItem>
                    {buildSpecialistName(
                      trainer.title,
                      trainer.firstname,
                      trainer.lastname
                    )}
                  </TrainerItem>
                );
              })}
            </TrainerList>
          </TrainersBlock>
        )}
      </Content>
    </Container>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  fluid: PropTypes.shape().isRequired,
  trainers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Header;
