// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Icon, Wrapper } from './InfoLine.styles';

const InfoLine = ({ children, renderIcon }) => {
  return (
    <Container>
      <Icon>{renderIcon}</Icon>

      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

InfoLine.propTypes = {
  children: PropTypes.node.isRequired,
  renderIcon: PropTypes.node.isRequired,
};

export default InfoLine;
