// vendors
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';
import { introStyle } from '../../../styles/global';
import { fontWeights } from '../../../styles/typography';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-right: -38px;
  margin-bottom: 90px;
  margin-left: -38px;

  > div {
    margin-right: 38px;
    margin-left: 38px;
  }
`;

export const Image = styled.div`
  flex-basis: 100%;

  flex-basis: 288px;
  flex-grow: 1;

  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-basis: 0;
  flex-flow: column;
  flex-grow: 999;
  justify-content: flex-end;
  min-width: 50%;
`;

export const Title = styled.h1`
  font-weight: ${fontWeights.body};
  font-size: 38px;
  line-height: ${48 / 38};
`;

export const TrainersBlock = styled.div`
  ${introStyle}

  margin-bottom: 0;

  > p {
    margin-bottom: 0;
  }
`;

export const TrainerList = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;
`;

export const TrainerItem = styled.li``;

export const StyledImage = styled(GatsbyImage)``;
