// vendors
import React from 'react';

const IconPrice = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 27.88 57.27'
    style={{ fill: 'currentColor' }}
    {...props}
  >
    <path d='M7.38,40.38A17.41,17.41,0,0,1,3,36.32l-.12-.17L.1,38.55l.11.14a18.48,18.48,0,0,0,5.26,4.74A16.31,16.31,0,0,0,13,45.53v4.76H16V45.53a14.45,14.45,0,0,0,8.64-3.31,10.32,10.32,0,0,0,3.28-8.1,12.9,12.9,0,0,0-.56-3.88A8.31,8.31,0,0,0,25.43,27,11.34,11.34,0,0,0,22,24.62a20.36,20.36,0,0,0-5.2-1.52L16,23V8.17A11.88,11.88,0,0,1,20.9,9.71a11.92,11.92,0,0,1,3.62,3.39l.12.16,2.85-2.14L27.38,11a14.53,14.53,0,0,0-4.72-4.34A16.12,16.12,0,0,0,16,4.7V0H13V4.76a20,20,0,0,0-4.64.87,11.47,11.47,0,0,0-3.8,2,8.69,8.69,0,0,0-2.45,3.16,10.19,10.19,0,0,0-.86,4.28,12.11,12.11,0,0,0,.59,3.83,8.2,8.2,0,0,0,2,3.17,11.43,11.43,0,0,0,3.48,2.35,20.84,20.84,0,0,0,5.2,1.49L13,26V42.19A12.7,12.7,0,0,1,7.38,40.38Zm5.29-18a17.79,17.79,0,0,1-3.26-.92A7.65,7.65,0,0,1,7,20a6,6,0,0,1-1.52-2.13,7.44,7.44,0,0,1-.53-3,5.75,5.75,0,0,1,2.18-4.83A11.06,11.06,0,0,1,13,8.1V22.48Zm3.47,4.11a11.55,11.55,0,0,1,6,2.38,6.84,6.84,0,0,1,2.05,5.41A7.11,7.11,0,0,1,22,39.83a10.22,10.22,0,0,1-6,2.36V26.5Z' />
    <rect y='54.45' width='27.72' height='2.83' />
  </svg>
);

export default IconPrice;
