// vendors
import React from 'react';

const IconRegister = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 62.66 58.64'
    style={{ fill: 'currentColor' }}
    {...props}
  >
    <path d='M29.09,58.64A29.32,29.32,0,0,1,29.09,0a29,29,0,0,1,18.6,6.77L45.56,9.31a25.66,25.66,0,0,0-16.47-6,26,26,0,1,0,25.79,26,26.75,26.75,0,0,0-.64-5.79l3.23-.72a30.13,30.13,0,0,1,.72,6.51A29.25,29.25,0,0,1,29.09,58.64Z' />
    <path d='M29.58,41.37h-.06a1.67,1.67,0,0,1-1.26-.66L15.12,23.28l2.65-2L29.68,37.1,60.17,2.27l2.49,2.18L30.83,40.81A1.66,1.66,0,0,1,29.58,41.37Z' />
  </svg>
);

export default IconRegister;
