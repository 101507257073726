// vendors
import { rem } from 'polished';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import { greaterThan, lessThan } from '../../../utils/mediaQuery';

const breakpoint = 832;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 40px;

  text-align: center;

  :last-child {
    margin-bottom: 0;
  }

  ${greaterThan(breakpoint)} {
    flex-flow: row;

    text-align: left;
  }

  p,
  ul {
    margin: 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
`;

export const Icon = styled.div`
  flex-shrink: 0;
  width: ${rem(32)};

  color: ${colors.azure};

  ${lessThan(breakpoint)} {
    margin-bottom: ${rem(16)};
  }

  ${greaterThan(breakpoint)} {
    margin-right: ${rem(28)};
  }

  svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;

export const Wrapper = styled.div`
  flex-grow: 999;
`;
