// vendors
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

const BuyTrainingButton = ({ children, id, url, title, price, ...props }) => {
  return (
    <Button
      className='snipcart-add-item'
      data-item-id={id}
      data-item-price={price}
      data-item-url={url}
      data-item-stackable='never'
      data-item-shippable='false'
      data-item-name={title}
      data-item-custom1-name='Nom'
      data-item-custom1-required='true'
      data-item-custom2-name='Prénom'
      data-item-custom2-required='true'
      data-item-custom3-name='Courriel'
      data-item-custom3-required='true'
      {...props}
    >
      {children}
    </Button>
  );
};

BuyTrainingButton.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default BuyTrainingButton;
