// vendors
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import colors from '../../styles/colors';
import { greaterThan, lessThan } from '../../utils/mediaQuery';
import { introStyle } from '../../styles/global';
import breakpoints from '../../styles/breakpoints';

export const selfBreakpoint = breakpoints[3];

export const MoreSection = styled.div`
  padding: ${rem(64)} ${rem(28)};

  ${greaterThan(768)} {
    padding: ${rem(112)} ${rem(80)} ${rem(160)};
  }

  background-color: ${colors.white};
`;

export const blockStyles = css`
  ${introStyle}
`;

export const ControlSection = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  max-width: ${rem(1280)};
  margin: auto;

  ${greaterThan(selfBreakpoint)} {
    grid-gap: ${rem(40)};
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CTAWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;

  ${lessThan(selfBreakpoint)} {
    button,
    a {
      min-width: 0;
      padding: ${rem(16)};

      border: 0;
    }

    a span,
    button span {
      margin: 0 !important;
    }
  }

  ${greaterThan(selfBreakpoint)} {
    grid-gap: 40px;
  }
`;

export const SectionTitle = styled.h2`
  ${introStyle}

  padding-bottom: 17px;

  border-bottom: 2px solid currentColor;
`;

export const StyledSection = styled.section`
  margin-top: 90px;
  margin-bottom: 90px;
`;

export const SplitPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px;

  > div {
    margin: 24px;
  }
`;

export const CTAPanel = styled.div`
  flex-grow: 1;
  flex-shrink: 0;

  text-align: center;
`;

export const ContentPanel = styled.div`
  flex-basis: 50%;
  flex-grow: 999;
`;

export const VenueList = styled.ul`
  padding: 0;

  list-style: none;
`;

export const Venue = styled.li`
  margin-bottom: 1em;
`;
